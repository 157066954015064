import { AccessTokenModel, AuthenticationProvider } from 'ngx-auth-utils';
import { AuthService } from '@core/http-gen/services/auth.service';
import { Observable, catchError, map, timeout, retry, throwError } from 'rxjs';
import { ConfirmRegisterUserDto, LoginDto, TokenResponseDto } from '@core/http-gen/models';
import { UtenteProfiloDto } from '@core/http-gen/models/utente-profilo-dto';

export class BackendAuthenticationProvider extends AuthenticationProvider {
    constructor(private authService: AuthService) {
        super();
    }

    private static mapAccessToken(tokenPair: TokenResponseDto): AccessTokenModel {
        {
            return {
                accessToken: tokenPair.token || '',
                refreshToken: tokenPair.refreshToken || '',
            };
        }
    }

    fetchUser(): Observable<UtenteProfiloDto> {
        return this.authService.apiAuthMeGet();
    }

    doLogin(credentials: LoginDto | ConfirmRegisterUserDto): Observable<AccessTokenModel> {
        if (this.isLoggingIn(credentials)) {
            return this.authService.apiAuthLoginPost({ body: credentials }).pipe(map(BackendAuthenticationProvider.mapAccessToken));
        }

        return this.authService.apiAuthRegisterConfirmPost({ body: credentials }).pipe(map(BackendAuthenticationProvider.mapAccessToken));
    }

    private isLoggingIn(credentials: LoginDto | ConfirmRegisterUserDto): boolean {
        return 'password' in credentials;
    }

    refreshToken(accessToken: string, refreshToken: string): Observable<AccessTokenModel> {
        return this.authService.apiAuthRefreshTokenPost({ body: { refreshToken } }).pipe(
            timeout(5000), // 5 seconds
            retry({ count: 3, delay: 1000 }),
            map(BackendAuthenticationProvider.mapAccessToken),
            catchError((error) => {
                if (error.status === 504) {
                    console.error('504 Gateway Timeout - Refresh token request took too long.');
                }
                return throwError(() => new Error('Refresh token failed!'));
            }),
        );
    }
}
