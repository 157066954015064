export class ApplicationException extends Error {
    public applicationExceptionCode: ApplicationExceptionCode;
    public message: string;
    public data: unknown;

    constructor(applicationExceptionCode: ApplicationExceptionCode, message: string, data?: unknown) {
        super(message);

        this.applicationExceptionCode = applicationExceptionCode;
        this.message = message;
        this.data = data;
    }
}

export enum ApplicationExceptionCode {
    NotHandled = 0,
    Database = 1,
    Authorization = 2,
    Validation = 3,
    Skebby = 4,
    Forbidden = 5,
    Tetra = 6,
    BodyNonCorretto = 7,
    Timeout = 8,

    CacheNonConfigurata = 10,

    UtenteGiaEsistente = 20,
    UtenteNonEsistente = 21,
    UtentePasswordErrata = 22,
    UtenteCodiceDueFattoriNonEsistente = 23,
    UtenteErroreRegistrazione = 24,
    UtenteNessunCorpoAssociato = 25,
    UtenteNessunNumeroTelefonoTrovato = 26,
    UtenteEsternoCorpiMultipliAssociati = 27,
    UtenteEsternoNessunCorpoAssociato = 28,
    UtenteResetPasswordGiaUtilizzata = 30,
    UtenteNonAttivo = 31,

    MatricolaNonValida = 40,
    UtenteNonRegistrabile = 41,
    TooManySessions = 42,
    AccessoPiattaformaNonConsentito = 43,

    RuoloNonEsistente = 50,

    OTPUtenteNonEsistente = 60,
    OTPUtenteNoDispositivoMobile = 61,
    OTPNessunaRichiestaUtente = 62,

    SelettivaNonVisibile = 70,
    SelettivaGiaPresente = 71,

    RecordGiaPresente = 100,

    GoogleAuth = 200,
    GoogleDrive = 201,
    GoogleCalendar = 202,

    EndpointDisabled = 1000,
}

export class ValidationResult {
    constructor(
        public validationField: string,
        public validationConstraint: string,
        public validationMessage: string,
    ) {}
}
